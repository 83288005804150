import React, { Component } from "react";

export default class Timer extends Component {
  componentDidMount() {
    this.myInterval = setInterval(() => {
      const {
        seconds,
        minutes,
        setSeconds,
        setMinutes,
        prog,
        setProg,
      } = this.props;

      if (seconds > 0) {
        setSeconds(seconds - 1);
        setProg(prog - 0.00555555555);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
          setProg(prog - 0.00555555555);
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    const { minutes, seconds } = this.props;
    return (
      <div>
        {minutes === 0 && seconds === 0 ? (
          <p>Store Closed!</p>
        ) : (
          <p className="store-closing-timer">
            {`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
          </p>
        )}
      </div>
    );
  }
}
