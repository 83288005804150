import React, { Component } from "react";

export default class HomeTimer extends Component {
  componentDidMount() {
    this.myInterval = setInterval(() => {
      const {
        homeSeconds,
        homeMinutes,
        setHomeSeconds,
        setHomeMinutes,
      } = this.props;

      if (homeSeconds > 0) {
        setHomeSeconds(homeSeconds - 1);
      }
      if (homeSeconds === 0) {
        if (homeMinutes === 0) {
          clearInterval(this.myInterval);
        } else {
          setHomeMinutes(homeMinutes - 1);
          setHomeSeconds(59);
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    const { homeMinutes, homeSeconds } = this.props;
    return (
      <div>
        {homeMinutes === 0 && homeSeconds === 0 ? (
          <p>Store Closed!</p>
        ) : (
          <p className="store-closing-timer">
            {`0hr ${homeMinutes}m ${
              homeSeconds < 10 ? `0${homeSeconds}s` : `${homeSeconds}s`
            }`}
          </p>
        )}
      </div>
    );
  }
}
