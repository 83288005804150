import React, { useState, useRef, useEffect } from "react";
import {
  InstantSearch,
  HierarchicalMenu,
  RefinementList,
  Pagination,
  ClearRefinements,
  Highlight,
  Hits,
  Panel,
  Configure,
  SearchBox,
  Snippet,
} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
import {
  ClearFiltersMobile,
  NoResults,
  ResultsNumberMobile,
  SaveFiltersMobile,
} from "./widgets";
import TopNavbar from "./NavbarSearch";
import ProgressBar from "./ProgressBar";
import Timer from "./Timer";
import HomeTimer from "./HomeTimer";
import NavbarHome from "./NavbarHome";
import { Badge } from "evergreen-ui";
import "./ShopTheme.css";
import "./Shop.css";
import "./Shop.mobile.css";
import "./widgets/Pagination.css";

import { Link, NavLink } from "react-router-dom";

const progressOptions = {
  strokeWidth: 0.3,
  easing: "easeInOut",
  duration: 1400,
  color: "#70707039",
  trailColor: "#acacac21",
  trailWidth: 0.2,
};

const searchClient = algoliasearch(
  "V522R626PP",
  "fbd0330949a32096e0d8b356f7cb201e"
);

const Hit = ({ hit }) => (
  <article className="hit">
    <header className="hit-image-container">
      <img src={hit.variantImage} alt={hit.productName} className="hit-image" />
    </header>

    <div className="hit-info-container">
      <p className="hit-category">{"Sample Store"}</p>
      <h1>
        <Highlight attribute="productName" tagName="mark" hit={hit} />
      </h1>
      <p className="hit-description">
        <Snippet attribute="productDescription" tagName="mark" hit={hit} />
      </p>

      <footer>
        <Badge color="yellow">{`$${Number(
          (Number(hit.variantPrice) / 100) * 50
        ).toFixed(2)}`}</Badge>
        <Badge color="neutral" marginLeft={8}>
          <strike>{`$${Number(hit.variantPrice).toFixed(2)}`}</strike>
        </Badge>
      </footer>
    </div>
  </article>
);

const Shop = (props) => {
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  const [searchState, setSearchState] = useState({});
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const [prog, setProg] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [homeMinutes, setHomeMinutes] = useState(0);
  const [homeSeconds, setHomeSeconds] = useState(5);

  useEffect(() => {
    console.log(`Refreshing Algolia Cache - setting ${refresh}`);
    setRefresh(false);
  }, [refresh]);

  function refreshButton() {
    setRefresh(true);
  }

  function openFilters() {
    document.body.classList.add("filtering");
    window.scrollTo(0, 0);
    window.addEventListener("keyup", onKeyUp);
    window.addEventListener("click", onClick);
  }

  function closeFilters() {
    document.body.classList.remove("filtering");
    containerRef.current.scrollIntoView();
    window.removeEventListener("keyup", onKeyUp);
    window.removeEventListener("click", onClick);
  }

  function onKeyUp(event) {
    if (event.key !== "Escape") {
      return;
    }

    closeFilters();
  }

  function onClick(event) {
    if (event.target !== headerRef.current) {
      return;
    }

    closeFilters();
  }

  function searchStateChange(searchstate) {
    setSearchState(searchstate);
  }

  return (
    <>
      {homeSeconds > 0 ? (
        <>
          <NavbarHome />

          <div className="demo-counter-div">
            <HomeTimer
              homeSeconds={homeSeconds}
              setHomeSeconds={setHomeSeconds}
              homeMinutes={homeMinutes}
              setHomeMinutes={setHomeMinutes}
            />
            <p className="store-closing-text">Store opens in</p>
          </div>
        </>
      ) : (
        <InstantSearch
          searchClient={searchClient}
          indexName="MXPROD"
          searchState={searchState}
          onSearchStateChange={searchStateChange}
          refresh={refresh}
        >
          <TopNavbar minutes={minutes} seconds={seconds} prog={prog} />
          <div className="header">
            <div>
              <Timer
                seconds={seconds}
                setSeconds={setSeconds}
                minutes={minutes}
                setMinutes={setMinutes}
                prog={prog}
                setProg={setProg}
              />

              <p className="store-closing-text">Until shop closes</p>
            </div>
            <SearchBox
              translations={{
                placeholder: `Search`,
              }}
              submit={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1."
                    transform="translate(1 1)"
                  >
                    <circle cx="7.11" cy="7.11" r="7.11" />
                    <path d="M16 16l-3.87-3.87" />
                  </g>
                </svg>
              }
            />
          </div>

          <Configure removeWordsIfNoResults="allOptional" hitsPerPage={36} />

          <main className="container" ref={containerRef}>
            <div className="container-wrapper">
              <section className="container-filters" onKeyUp={onKeyUp}>
                <div className="container-header">
                  <h2>Filters</h2>

                  <div className="clear-filters" data-layout="desktop">
                    <ClearRefinements
                      translations={{
                        reset: (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                            >
                              <g fill="none" fillRule="evenodd" opacity=".4">
                                <path d="M0 0h11v11H0z" />
                                <path
                                  fill="#000"
                                  fillRule="nonzero"
                                  d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                                />
                              </g>
                            </svg>
                            Clear filters
                          </>
                        ),
                      }}
                    />
                  </div>

                  <div className="clear-filters" data-layout="mobile">
                    <ResultsNumberMobile />
                  </div>
                </div>

                <div className="container-body">
                  <Panel header="Category">
                    <HierarchicalMenu
                      attributes={[
                        "hierarchicalCategories.lvl0",
                        "hierarchicalCategories.lvl1",
                        "hierarchicalCategories.lvl2",
                      ]}
                      limit={20}
                    />
                  </Panel>

                  <Panel header="Brands">
                    <RefinementList
                      attribute="productBrand"
                      searchable={true}
                      translations={{
                        placeholder: "Search for brands…",
                      }}
                    />
                  </Panel>
                </div>
              </section>

              <footer className="container-filters-footer" data-layout="mobile">
                <div className="container-filters-footer-button-wrapper">
                  <ClearFiltersMobile containerRef={containerRef} />
                </div>

                <div className="container-filters-footer-button-wrapper">
                  <SaveFiltersMobile onClick={closeFilters} />
                </div>
              </footer>
            </div>

            <section className="container-results">
              <header className="container-header container-options"></header>

              <Hits hitComponent={Hit} />
              <NoResults />

              <footer className="container-footer">
                <Pagination
                  padding={2}
                  showFirst={false}
                  showLast={false}
                  translations={{
                    previous: (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                      >
                        <g
                          fill="none"
                          fillRule="evenodd"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.143"
                        >
                          <path d="M9 5H1M5 9L1 5l4-4" />
                        </g>
                      </svg>
                    ),
                    next: (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                      >
                        <g
                          fill="none"
                          fillRule="evenodd"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.143"
                        >
                          <path d="M1 5h8M5 9l4-4-4-4" />
                        </g>
                      </svg>
                    ),
                  }}
                />
              </footer>
            </section>
          </main>

          <aside data-layout="mobile">
            <button
              className="filters-button"
              data-action="open-overlay"
              onClick={openFilters}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
                <path
                  d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
                  stroke="#fff"
                  strokeWidth="1.29"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Filters
            </button>
          </aside>
        </InstantSearch>
      )}
    </>
  );
};

export default Shop;
/*
 <div className="shop-timer-hero">
          <p className="store-closing-text">Until store closes</p>
          <Timer
            minutes={minutes}
            setMinutes={setMinutes}
            seconds={seconds}
            setSeconds={setSeconds}
            prog={prog}
            setProg={setProg}
          />
        </div>*/

/*

 <div className="header">
        <div className="progress-div">
          <Timer
            minutes={minutes}
            setMinutes={setMinutes}
            seconds={seconds}
            setSeconds={setSeconds}
            prog={prog}
            setProg={setProg}
          />
        </div>
        <button onClick={refreshButton}>Refresh cache</button>
        <SearchBox
          translations={{
            placeholder: `Search`,
          }}
          submit={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 18 18"
            >
              <g
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1."
                transform="translate(1 1)"
              >
                <circle cx="7.11" cy="7.11" r="7.11" />
                <path d="M16 16l-3.87-3.87" />
              </g>
            </svg>
          }
        />
      </div>




       <ProgressBar.Line
        progress={prog}
        options={progressOptions}
        initialAnimate={true}
      />


      <Timer
          minutes={minutes}
          setMinutes={setMinutes}
          seconds={seconds}
          setSeconds={setSeconds}
          prog={prog}
          setProg={setProg}
        />
      */
