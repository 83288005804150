import React, { useState } from "react";
import NavbarHome from "./NavbarHome";
import { Pane, Menu } from "evergreen-ui";

function About() {
  const [selected, setSelected] = useState("asc");
  const [selectedTwo, setSelectedTwo] = useState("email");

  return (
    <>
      <NavbarHome />
      <div className="about-div">
        <h2 className="about-heading">FAQ</h2>
        <hr className="divider-solid"></hr>
        <h2 className="about-heading">What is Flashdrop?</h2>
        <p className="about-text">
          Flashdrop is a flash sale marketplace offering discount{" "}
          <strong>drops</strong> of up to <strong>70%</strong>. All of our sales
          take place within a limited time window, and inventory is sold on a
          first-come, first-served basis.
        </p>
        <h2 className="about-heading">How does it work?</h2>
        <p className="about-text">
          When the countdown timer reaches zero, the marketplace opens and there
          is a <strong>3 minute</strong> window to purchase heaviliy discounted
          products. Once the three minutes is up or all inventory is sold, the
          store closes until the next drop.
        </p>
      </div>
    </>
  );
}

export default About;
