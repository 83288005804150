import React, { useState } from "react";
import NavbarHome from "./NavbarHome";
import { TextInput, Button } from "evergreen-ui";

function Home() {
  const [count, setCount] = useState(0);
  return (
    <>
      <NavbarHome />
      <div className="home-counter-div">
        <h1 className="coming-soon-text">Coming Soon 🎉</h1>
        <h4 className="informed-text">
          Want to stay informed? Enter your email below
        </h4>
        <div className="email-input-div">
          <TextInput
            name="text-input-name"
            placeholder="something@example.com"
            height={40}
            width={300}
          />
          <Button height={38} marginBottom={1} marginLeft={5} color={"#585858"}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
}

export default Home;
